import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import {
  DetailedStatus,
  LiveControllerDetailedWsNotification,
  SearchRequest,
  SearchResponse,
  WorkflowRequest
} from 'services/Api';
import { Error } from 'services/Error';
import { RootState } from 'redux/reducers';
import {
  RequestState,
  generateRequestState,
  setRequestError,
  startRequest
} from 'utils/reducerHelpers';

const workflowRequestAdapter = createEntityAdapter<WorkflowRequest>({
  selectId: workflowRequest => workflowRequest.id
});
const workflowRequestSelector = workflowRequestAdapter.getSelectors<any>(
  state => state.workflowRequest.workflowRequests.items
);

export const selectWorkflowRequests = workflowRequestSelector.selectAll;
export const selectWorkflowRequestIds = workflowRequestSelector.selectIds;
export const selectWorkflowRequestById = workflowRequestSelector.selectById;
export const selectWorkflowRequestTotalCount = (state: any) =>
  state.workflowRequest.workflowRequests.totalCount;
export const selectWorkflowRequestCount = (state: any) =>
  state.workflowRequest.workflowRequests.itemCount;
export const selectWorkflowRequestSearchParams = (state: any) =>
  state.workflowRequest.workflowRequests.searchParams;
export const selectWorkflowRequestFacets = (state: any) =>
  state.workflowRequest.workflowRequests.facets;
export const selectClipOrder = (state: RootState) => state.workflowRequest.clipOrder;

export const selectListWorkflowRequestState = (state: any) => state.workflowRequest.request.list;
export const selectClipWorkflowRequestState = (state: any) => state.workflowRequest.request.create;
export const selectCreateWorkflowRequestState = (state: any) =>
  state.workflowRequest.request.create;
export const selectUpdateWorkflowRequestState = (state: any) =>
  state.workflowRequest.request.update;
export const selectDeleteWorkflowRequestState = (state: any) =>
  state.workflowRequest.request.delete;
export const selectExportedIngests = (state: any) => state.workflowRequest.workflowRequests.exports;
export const selectSavedSearch = (state: any): Record<string, any> =>
  state.workflowRequest.workflowRequests.savedSearch;

export type SearchWorkflowRequestsPayload = PayloadAction<SearchRequest & { refresh?: boolean }>;

type WorkflowRequestState = RequestState & {
  workflowRequests: {
    facets?: Record<string, Record<string, number>>;
    items: ReturnType<typeof workflowRequestAdapter.getInitialState>;
    itemCount: number;
    totalCount: number;
    searchParams: SearchRequest;
    savedSearch: Record<string, any>;
  };
  clipOrder: WorkflowRequest | null;
};

const initialState: WorkflowRequestState = {
  request: generateRequestState(['list', 'create', 'update', 'delete'], ['list']),
  workflowRequests: {
    facets: {},
    items: workflowRequestAdapter.getInitialState(),
    itemCount: 0,
    totalCount: 0,
    searchParams: {
      filters: []
    },
    savedSearch: {}
  },
  clipOrder: null
};

const workflowRequestSlice = createSlice({
  name: 'workflowRequest',
  initialState,
  reducers: {
    clearWorkflowRequests(state, action: PayloadAction<undefined>) {
      workflowRequestAdapter.removeAll(state.workflowRequests.items);
      state.workflowRequests.itemCount = 0;
      state.workflowRequests.totalCount = 0;
      // keep search params
      // state.workflowRequests.searchParams = {
      //   facets: ["*"],
      //   filters: []
      // }
      state.request.list.error = undefined;
    },
    searchWorkflowRequests(state, action: SearchWorkflowRequestsPayload) {
      const { refresh, ...searchParams } = action.payload;
      if (refresh) {
        state.request.list.refreshing = true;
      } else {
        state.request.list.loading = true;
        state.workflowRequests.searchParams = searchParams;
      }
      state.request.list.error = undefined;
    },
    searchWorkflowRequestsLoadMore(state, action: PayloadAction<number | undefined>) {
      startRequest(state.request.list);
    },
    workflowRequests(state, action: PayloadAction<SearchResponse>) {
      const { items, itemCount, totalCount, facets } = action.payload;
      workflowRequestAdapter.setAll(state.workflowRequests.items, items as WorkflowRequest[]);
      state.request.list.loading = false;
      state.request.list.refreshing = false;
      state.workflowRequests.itemCount = itemCount || 0;
      state.workflowRequests.totalCount = totalCount;
      state.workflowRequests.facets = facets;
    },
    workflowRequestMoreLoaded(state, action: PayloadAction<SearchResponse>) {
      const { items, itemCount, totalCount, facets } = action.payload;
      workflowRequestAdapter.addMany(state.workflowRequests.items, items as WorkflowRequest[]);
      state.request.list.loading = false;
      state.workflowRequests.itemCount += itemCount || 0;
      state.workflowRequests.totalCount = totalCount;
      state.workflowRequests.facets = facets;
    },
    listWorkflowRequestsError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.list, action.payload);
    },
    createClipWorkflowRequest: {
      reducer(state, action: PayloadAction<any>) {
        startRequest(state.request.create);
      },
      prepare: (
        mediaResourceId,
        timecodeIn,
        timecodeOut,
        outputFormatId,
        clipName,
        mediaResourceVersion
      ) => ({
        payload: {
          mediaResourceId,
          timecodeIn,
          timecodeOut,
          outputFormatId,
          clipName,
          mediaResourceVersion
        }
      })
    },
    clipWorkflowRequestCreated(state, action: PayloadAction<WorkflowRequest>) {
      state.clipOrder = action.payload;
      state.request.create.loading = false;
    },
    clearClipWorkflowRequestCreated(state) {
      state.clipOrder = null;
    },
    clipWorkflowRequestError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.create, action.payload);
    },
    createWorkflowRequest(state, action) {
      startRequest(state.request.create);
    },
    createWorkflowRequestError(state, action) {
      setRequestError(state.request.create, action.payload);
    },
    workflowRequestCreated(state, action) {
      workflowRequestAdapter.addOne(state.workflowRequests.items, action.payload);
      state.workflowRequests.itemCount += 1;
      state.workflowRequests.totalCount += 1;
      state.request.create.loading = false;
    },
    updateWorkflowRequest(state, action) {
      startRequest(state.request.update);
    },
    updateWorkflowRequestError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.update, action.payload);
    },
    workflowRequestUpdated(state, action) {
      workflowRequestAdapter.updateOne(state.workflowRequests.items, {
        id: action.payload.id,
        changes: action.payload
      });
      state.request.update.loading = false;
    },
    deleteWorkflowRequest(state, action) {
      startRequest(state.request.delete);
    },
    deleteWorkflowRequestError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.delete, action.payload);
    },
    workflowRequestDeleted(state, action) {
      workflowRequestAdapter.removeOne(state.workflowRequests.items, action.payload);
      state.request.delete.loading = false;
      state.workflowRequests.itemCount -= 1;
      state.workflowRequests.totalCount -= 1;
    },
    sendCommandToWorkflowRequest(state, action) {
      startRequest(state.request.update);
    },
    updateSavedSearch(state, action) {
      state.workflowRequests.savedSearch = action.payload;
    }
  }
});

export const {
  clearWorkflowRequests,
  createClipWorkflowRequest,
  clipWorkflowRequestCreated,
  clearClipWorkflowRequestCreated,
  clipWorkflowRequestError,
  searchWorkflowRequests,
  searchWorkflowRequestsLoadMore,
  workflowRequests,
  workflowRequestMoreLoaded,
  listWorkflowRequestsError,
  createWorkflowRequest,
  createWorkflowRequestError,
  workflowRequestCreated,
  updateWorkflowRequest,
  updateWorkflowRequestError,
  workflowRequestUpdated,
  deleteWorkflowRequest,
  deleteWorkflowRequestError,
  workflowRequestDeleted,
  sendCommandToWorkflowRequest,
  updateSavedSearch
} = workflowRequestSlice.actions;
export type { DetailedStatus, LiveControllerDetailedWsNotification, WorkflowRequest };
export default workflowRequestSlice.reducer;
