import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/reducers';
import { AccessResponse, MemberApprobation, MemberApprobationResponse, Member } from 'services/Api';
import { AuthState } from 'services/Auth';
import { Error } from 'services/Error';
import {
  generateRequestState,
  startRequest,
  setRequestError,
  RequestState
} from 'utils/reducerHelpers';

export const tokenSelector = (state: RootState) => state.auth.accessToken;
export const selectAuthUser = (state: RootState): AccessResponse => state.auth.currentUser;
export const selectAuthState = (state: RootState) => state.auth.state;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectImpersonatedUser = (state: RootState): AccessResponse =>
  state.auth.impersonatedUser;
export const selectImpersonateUserRequestState = (state: RootState) =>
  state.auth.request.impersonateUser;
export const selectAddApprobationRequestState = (state: RootState) =>
  state.auth.request.addApprobation;

export const selectAuthRequestState = createSelector(
  (state: RootState) => state.auth.loading,
  (state: RootState) => state.auth.error,
  (loading, error) => ({
    loading,
    error
  })
);

type AuthReducerState = RequestState & {
  state: string;
  loading: boolean;
  error: Error | null;
  accessToken: string | null;
  currentUser: AccessResponse | null;
  impersonatedUser: AccessResponse | null;
};

const initialState: AuthReducerState = {
  request: generateRequestState(['addApprobation', 'impersonateUser'], []),
  state: AuthState.SignIn,
  loading: false,
  error: null,
  accessToken: null,
  currentUser: null,
  impersonatedUser: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: {
      reducer(state) {
        state.loading = true;
      },
      prepare: ({ email, password, language }) => ({
        payload: {
          email,
          password,
          language
        }
      })
    },
    completeNewPassword: {
      reducer(state) {
        state.loading = true;
      },
      prepare: password => ({
        payload: {
          password
        }
      })
    },
    forgotPassword: {
      reducer(state) {
        state.loading = true;
      },
      prepare: email => ({
        payload: {
          email
        }
      })
    },
    forgotPasswordSubmit: {
      reducer(state) {
        state.loading = true;
      },
      prepare: (email: string, code: string, newPassword: string) => ({
        payload: {
          email,
          code,
          newPassword
        }
      })
    },
    signOut(state, _action: PayloadAction<unknown>) {
      state.loading = true;
    },
    state: {
      // TODO: use correct typing
      reducer(state, action: PayloadAction<any>) {
        return {
          ...state,
          state: action.payload.state,
          error: action.payload.error,
          loading: false
        };
      },
      prepare(state, error?) {
        return {
          payload: {
            state,
            error
          }
        };
      }
    },
    accessToken(state, action) {
      state.accessToken = action.payload;
    },
    fetchCurrentUser(state, _action: PayloadAction<unknown>) {
      state.loading = true;
    },
    currentUser(state, action) {
      state.currentUser = action.payload;
      state.loading = false;
    },
    impersonateUser(state, _action: PayloadAction<unknown>) {
      startRequest(state.request.impersonateUser);
    },
    impersonateUserError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.impersonateUser, action.payload);
    },
    userImpersonated(state, action: PayloadAction<AccessResponse>) {
      state.impersonatedUser = action.payload;
      state.request.impersonateUser.loading = false;
    },
    stopImpersonateUser(state, _action: PayloadAction<unknown>) {
      state.impersonatedUser = null;
    },
    addApprobations(state, _action: PayloadAction<unknown>) {
      startRequest(state.request.addApprobation);
    },
    addApprobationsError(state, action: PayloadAction<Error>) {
      setRequestError(state.request.addApprobation, action.payload);
    },
    approbationsAdded(state, action: PayloadAction<MemberApprobationResponse[]>) {
      const addedApprobations = action.payload;
      const userApprobations = state.impersonatedUser
        ? state.impersonatedUser.approbations
        : state.currentUser!.approbations;
      addedApprobations.forEach(addedApprobation => {
        const index = userApprobations?.findIndex(
          userApprobation => userApprobation.documentId === addedApprobation.documentId
        );
        if (index !== -1) {
          userApprobations[index] = addedApprobation;
        } else {
          userApprobations?.push(addedApprobation);
        }
      });
      state.request.addApprobation.loading = false;
    }
  }
});

export const {
  accessToken,
  state,
  signIn,
  completeNewPassword,
  forgotPassword,
  forgotPasswordSubmit,
  signOut,
  fetchCurrentUser,
  currentUser,
  impersonateUser,
  impersonateUserError,
  userImpersonated,
  stopImpersonateUser,
  addApprobations,
  addApprobationsError,
  approbationsAdded
} = authSlice.actions;
export type { AccessResponse } from 'services/Api';
export default authSlice.reducer;
